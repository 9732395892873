<template>
  <div id="banks" class="contenedor">
    <loader :show="show" />
    <sesion :expiration="expiration" />
    <Sidebar></Sidebar>
    <v-container fluid class="base text-center">
      <v-row no-gutters class="titulo ml-10 mt-5">LISTA DE BANCOS</v-row>
      <v-row no-gutters justify="end" class="mr-12">
        <v-col cols="12" lg="6" md="6" sm="10" xs="12">
          <v-card-title>
            <router-link to="/addBank" id="nuevoBanco">
              <button class="breakSearch botonAmarillo">Alta de Banco</button>
            </router-link>
            <v-text-field
              class="mr-2"
              v-model="search"
              append-icon="search"
              label="Buscador"
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>
        </v-col>
      </v-row>
      <v-row no-gutters class="datos pa-0" style="height: auto !important">
        <v-card style="width: 100%; background: rgb(199, 195, 199) !important">
          <v-data-table
            :headers="headers"
            :items="banks"
            :search="search"
            class="elevation-2 text--dark"
            loading-text="Cargando datos... Por favor espere"
            :items-per-page="5"
            :no-results-text="'No se encontraron incidencias'"
            :no-data-text="'No hay datos'"
            :footer-props="{
              'items-per-page-text': 'Bancos por página',
              'items-per-page': [5, 10, 15],
              'items-per-page-all-text': 'Todos',
              'items-per-page-options': [5, 10],
              'loading-text': 'Obteniendo datos... Por favor espere',
              'no-data-text': 'No hay datos...',
              'no-results-text': 'No se encontraron incidencias'
            }"
          >
            <template v-slot:no-data>
              <v-alert :value="true" color="#ffffff" icon="warning">
                <p style="color: black">No hay datos en sistema ...</p>
              </v-alert>
            </template>
            <template v-slot:body="{ items }">
              <tbody>
                <tr
                  v-for="item in items"
                  :key="item.id"
                  :class="{ selectedRow: item === selectedItem }"
                >
                  <td class="text-sm-center">{{ item.nombreCorto }}</td>
                  <td class="text-sm-center">{{ item.razonSocial }}</td>
                  <td class="justify-center layout px-0">
                    <v-icon
                      color="#717171"
                      small
                      class="mr-6"
                      @click="editItem(item)"
                      >fa-edit</v-icon
                    >
                    <v-icon
                      color="#717171"
                      small
                      class="mr-6"
                      @click="viewItem(item)"
                      >fa-eye</v-icon
                    >
                    <v-icon color="red" small @click="temporal(item)"
                      >fa-trash-alt</v-icon
                    >
                  </td>
                </tr>
              </tbody>
            </template>
          </v-data-table>
        </v-card>
      </v-row>
    </v-container>
    <!--Aceptación de acciones-->
    <v-dialog v-model="advice" width="400">
      <v-card>
        <v-card-title class="red white--text">
          <span class="headline">Aviso</span>
        </v-card-title>
        <v-card-text>
          <br />
          <h3 style="text-align: center">{{ respuesta }}</h3>
          <br />
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn
            color="red"
            text
            @click="
              advice = false;
              id = '';
            "
            >Cancelar</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn color="success" text @click="deleteItem()">Aceptar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--DIALOG DE DATOS-->
    <v-dialog v-model="dialog" scrollable max-width="450px">
      <v-card>
        <v-card-title class="orange white--text"
          >Información de Banco</v-card-title
        >
        <v-card-text style="height: 200px" id="info">
          <div class="form-group mt-5">
            <label>Clave CFDI:</label>
            {{ cfdi }}
          </div>
          <div class="form-group">
            <label>Clave SPEI:</label>
            {{ spei }}
          </div>
          <div class="form-group">
            <label>Nombre Comercial:</label>
            {{ name }}
          </div>
          <div class="form-group">
            <label>Razón Social:</label>
            {{ reason }}
          </div>
          <div class="form-group">
            <label>Inicio de Vigencia:</label>
            {{ startDate }}
          </div>
          <div class="form-group">
            <label>Fin de Vigencia:</label>
            {{ finishDate }}
          </div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialog = false"
            >Cerrar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import Sidebar from "@/components/Sidebar.vue";
import Loader from "@/components/Loader";
import Sesion from "@/components/Sesion";
export default {
  components: {
    Sidebar,
    Loader,
    Sesion,
  },
  data() {
    return {
      show: false,
      expiration: false,
      search: "",
      dialog: false,
      selectedItem: null,
      headers: [
        {
          text: "Nombre Comercial",
          align: "center",
          value: "nombreComercial",
        },
        {
          text: "Razón Social",
          align: "center",
          value: "razonSocial",
        },
        {
          text: "Editar  / Ver / Eliminar",
          value: "actions",
          align: "center",
          sortable: false,
        },
      ],
      banks: [
        {
          id: "1",
          spei: "10584521004651",
          cfdi: "30948541",
          nombreComercial: "BANAMEX",
          razonSocial:
            "Banco Nacional de México, S.A., Institución de Banca Múltiple, Grupo Financiero Banamex",
          inicioVigencia: "25/04/2020",
          finVigencia: "20/05/2020",
        },
        {
          id: "2",
          spei: "10584521004651",
          cfdi: "30948541",
          nombreComercial: "BANCOMEXT",
          razonSocial:
            "Banco Nacional de Comercio Exterior, Sociedad Nacional de Crédito, Institución de Banca de Desarrollo",
          inicioVigencia: "25/04/2020",
          finVigencia: "20/05/2020",
        },
      ],
      respuesta: "",
      advice: false,
      idBank: "",
      cfdi: "",
      spei: "",
      name: "",
      reason: "",
      startDate: "",
      finishDate: "",
      confirmation: false,
      edit: false,
      vacio: false,
    };
  },
  methods: {
    listar() {
      this.show = true;
      axios
        .get(Server + "/bancos", {
          headers: {
            Authorization: localStorage.token,
          },
        })
        .then((response) => {
          this.banks = response.data;
          this.show = false;
        })
        .catch((e) => {
          console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          }
        });
    },
    editItem(item) {
      console.log("El id es: " + item.id);
      //this.idBank = item.id;
      localStorage.idBank = item.id;
      this.$router.push("/editBank");
    },
    validacion: function () {
      this.errors = [];
      this.validaCfdi();
      this.validaSpei();
      if (this.name == "" || this.name == " ") {
        this.errors.push("Se debe especificar el nombre comercial.");
      }
      if (this.reason == "" || this.reason == " ") {
        this.errors.push("Se debe especificar la razón social.");
      }
      if (this.startDate == "") {
        this.errors.push("Se debe especificar la fecha de inicio de vigencia.");
      }
      if (this.finishDate == "") {
        this.errors.push("Se debe especificar la fecha de fin de vigencia.");
      }
      if (this.errors.length == 0) {
        this.edit = false;
        this.vacio = false;
        return this.editar();
      } else {
        this.vacio = true;
      }
    },
    editar() {
      //console.log("El id para la api es" + this.idBank);
      this.respuesta = "";
      this.show = true;
      axios
        .put(
          Server + "/bancos/" + this.idBank,
          {
            id: this.idBank,
            nombreComercial: this.name,
            razonSocial: this.reason,
            cfdi: this.cfdi,
            spei: this.spei,
            inicioVigencia: this.startDate,
            finVigencia: this.finVigencia,
          },
          {
            headers: {
              Authorization: localStorage.token,
            },
          }
        )
        .then((response) => {
          this.idBank = "";
          this.name = "";
          this.reason = "";
          this.startDate = "";
          this.finishDate = "";
          this.spei = "";
          this.cfdi = "";
          this.respuesta = "El elemento fue actualizado con éxito";
          this.listar();
          this.show = false;
          this.confirmation = true;
        })
        .catch((e) => {
          console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          } else {
            this.idBank = "";
            this.name = "";
            this.reason = "";
            this.startDate = "";
            this.finishDate = "";
            this.spei = "";
            this.cfdi = "";
            this.respuesta = "El elemento no fue actualizado";
            this.confirmation = false;
          }
        });
    },
    /*
      temporal sirve para mostrar el nombre del dato a eliminar en la Alerta ayuda al usuario a no borrar el equivocado
      guarda solo el id del dato
    */
    temporal(item) {
      console.log("El id es: " + item.id);
      this.idBank = item.id;
      this.respuesta =
        "¿ Seguro que desea eliminar el elemento " + item.nombreCorto + " ?";
      this.advice = true;
    },
    deleteItem() {
      this.advice = false;
      //console.log("Ingreso a eliminar el dato con id: " + this.idBank);
      this.respuesta = "";
      this.show = true;
      axios
        .delete(Server + "/bancos/" + this.idBank, {
          headers: {
            Authorization: localStorage.token,
          },
        })
        .then((response) => {
          this.idBank = "";
          this.respuesta = "El elemento fue eliminado con éxito";
          this.listar();
          this.show = false;
          this.confirmation = true;
        })
        .catch((e) => {
          console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          } else {
            this.idBank = "";
            this.respuesta = "El elemento no fue eliminado";
            this.confirmation = false;
          }
        });
    },
    viewItem(item) {
      this.cfdi = item.claveCFDI;
      this.spei = item.claveSPEI;
      this.name = item.nombreCorto;
      this.reason = item.razonSocial;
      this.startDate = null;
      this.finishDate = null;
      this.dialog = true;
    },
    alfaNumerico: function (evt) {
      var regex = new RegExp("^[a-zA-ZÀ-ÿ\u00f1\u00d10-9 ]+$"); // Letras y números
      var str = String.fromCharCode(!evt.charCode ? evt.which : evt.charCode);
      if (regex.test(str)) {
        return true;
      }
      evt.preventDefault();
      return false;
    },
    aux() {
      this.edit = false;
      this.idBank = "";
      this.name = "";
      this.reason = "";
      this.startDate = "";
      this.finishDate = "";
      this.spei = "";
      this.cfdi = "";
      this.vacio = false;
    },
  },
  created() {
    this.listar();
  },
};
</script>